import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import {ROUTE_NAME} from '@/constants/route.constants'
import {uiHelper} from '@/util'

Vue.use(VueRouter)
// Vue.use(VueMeta)
const homePage = {name: ROUTE_NAME.HOME}

const loginGuard = function (to, from, next) {
    uiHelper.isAuthenticated() ? next() : next(homePage)
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        // {path: '/home', redirect: '/home/', pathToRegexpOptions: {strict: true}},
        { path: '/home/', redirect: '/', pathToRegexpOptions: { strict: true } },
        {
            path: '/sc',
            name: ROUTE_NAME.SHORTCUT,
            meta: {layout: 'layout-master'},
            component: require('@/views/sc/shortcut.vue').default,
        },
        {
            path: '/reset-password',
            name: ROUTE_NAME.RESET_PASSWORD,
            meta: {layout: 'layout-master'},
            component: require('@/views/profile/resetPassword.vue').default,
        },
        {
            path: '/contact-verification',
            name: ROUTE_NAME.CONTACT_VERIFICATION,
            meta: {layout: 'layout-master'},
            component: require('@/views/profile/contactVerification.vue').default,
        },
        {
            path: '/register-mobile',
            name: ROUTE_NAME.REGISTER_MOBILE,
            meta: {
                layout: 'layout-master',
            },
            component: require('@/views/registerMobile.vue').default,
        },
        {path: '/register/', redirect: { name: ROUTE_NAME.REGISTER }, pathToRegexpOptions: {strict: true}},

        {
            path: '/:lang?/register',
            name: ROUTE_NAME.REGISTER,
            props: { registerErrorMsg: '', openLoginDialog: () => {}, isMobileRegistration: true },
            meta: {
                layout: 'layout-master',
            },
            component: require('@/views/mainRegister.vue').default,
            // component: require('@/views/register.vue').default,
            pathToRegexpOptions: {strict: true}
        },
        {
            path: '/:lang?/affiliate/referral',
            name: ROUTE_NAME.AFFILIATE_REFFERAL,
            meta: {layout: 'layout-master'},
            component: require('@/views/affiliate/referral.vue').default,
        },
        // {path: '/sportsbook', redirect: '/sportsbook/', pathToRegexpOptions: {strict: true}},
        {
            path: '/:lang?/sportsbook',
            meta: {
                layout: 'layout-master',
            },
            component: require('@/views/sportsbook/sportsbookLayout.vue').default,
            pathToRegexpOptions: {strict: true},
            children: [
                {
                    path: '/:lang?/sportsbook',
                    name: ROUTE_NAME.SPORTSBOOK,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'sb'},
                    component: require('@/views/game/game.vue').default,
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.SPORTSBOOK_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'sb'},
                    component: require('@/views/game/game.vue').default,
                }
            ]
        },
        {
            path: '/:lang?/fishing',
            meta: {
                layout: 'layout-master',
            },
            component: require('@/views/fishing/fishingLayout.vue').default,
            children: [
                {
                    path: '/:lang?/fishing',
                    name: ROUTE_NAME.FISHING,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'fishing'},
                    component: require('@/views/game/game.vue').default
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.FISHING_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'fishing'},
                    component: require('@/views/game/game.vue').default
                }
            ]
        },
        {
            path: '/:lang?/special',
            component: require('@/views/special/specialLayout.vue').default,
            children: [
                {
                    path: '/:lang?/special',
                    name: ROUTE_NAME.SPECIAL,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/special/special.vue').default,
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.SPECIAL_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/special/special.vue').default,
                }
            ]
        },
        // {path: '/casino', redirect: '/casino/', pathToRegexpOptions: {strict: true}},
        {

            path: '/:lang?/casino',
            meta: {
                layout: 'layout-master',
            },
            component: require('@/views/casino/casinoLayout.vue').default,
            pathToRegexpOptions: {strict: true},
            children: [
                {
                    path: '/:lang?/casino',
                    name: ROUTE_NAME.CASINO,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'ld'},
                    component: require('@/views/game/game.vue').default,
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.CASINO_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'ld'},
                    component: require('@/views/game/game.vue').default,
                }
            ]
        },
        // {path: '/:lang?/slot', redirect: '/slot/', pathToRegexpOptions: {strict: true}},
        {
            path: '/:lang?/slot',
            meta: {
                layout: 'layout-master',
            },
            component: require('@/views/slot/slotLayout.vue').default,
            children: [
                {
                    path: '/:lang?/slot',
                    name: ROUTE_NAME.SLOT,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'rng'},
                    component: require('@/views/slot/slot.vue').default,
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.SLOT_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'rng'},
                    component: require('@/views/slot/slot.vue').default,
                },
                {
                    path: ':provider/:gamename',
                    name: ROUTE_NAME.SLOT_BY_PROVIDER_AND_GAMENAME,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'rng'},
                    component: require('@/views/slot/slot.vue').default,
                },
                {
                    path: 'game/:gamename',
                    name: ROUTE_NAME.SLOT_BY_GAMENAME,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'rng'},
                    component: require('@/views/slot/slot.vue').default,
                }
            ]
        },
        // {path: '/tablegames', redirect: '/tablegames/', pathToRegexpOptions: {strict: true}},
        {
            path: '/:lang?/tablegames',
            meta: {
                layout: 'layout-master',
            },
            component: require('@/views/table/tableLayout.vue').default,
            children: [
                {
                    path: '/:lang?/tablegames',
                    name: ROUTE_NAME.TABLE_GAMES,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'table'},
                    component: require('@/views/game/game.vue').default,
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.TABLE_GAMES_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'table'},
                    component: require('@/views/game/game.vue').default,
                }
            ]
        },
        // {path: '/lottery', redirect: '/lottery/', pathToRegexpOptions: {strict: true}},
        {
            path: '/:lang?/lottery',
            meta: {
                layout: 'layout-master',
            },
            component: require('@/views/lottery/lotteryLayout.vue').default,
            children: [
                {
                    path: '/:lang?/lottery',
                    name: ROUTE_NAME.LOTTERY,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'lottery'},
                    component: require('@/views/game/game.vue').default,
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.LOTTERY_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'lottery'},
                    component: require('@/views/game/game.vue').default,
                }
            ]
        },
        // {path: '/crash', redirect: '/crash/', pathToRegexpOptions: {strict: true}},
        {
            path: '/:lang?/crash',
            meta: {
                layout: 'layout-master',
            },
            component: require('@/views/crash/crashLayout.vue').default,
            children: [
                {
                    path: '/:lang?/crash',
                    name: ROUTE_NAME.CRASH,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'crash'},
                    component: require('@/views/game/game.vue').default,
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.CRASH_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'crash'},
                    component: require('@/views/game/game.vue').default,
                }
            ]
        },
        // {path: '//cricket', redirect: '/cricket/', pathToRegexpOptions: {strict: true}},
        {
            path: '/:lang?/cricket',
            meta: {
                layout: 'layout-master',
            },
            component: require('@/views/cricket/cricketLayout.vue').default,
            children: [
                {
                    path: '/:lang?/cricket',
                    name: ROUTE_NAME.CRICKET,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'cricket'},
                    component: require('@/views/game/game.vue').default,
                },
                {
                    path: ':provider',
                    name: ROUTE_NAME.CRICKET_BY_PROVIDER,
                    meta: {layout: 'layout-master'},
                    props: {providerSelected: 'cricket'},
                    component: require('@/views/game/game.vue').default,
                }
            ]
        },
        {
            path: '/:lang?/playgame/:provider/:type',
            name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
            meta: {layout: 'blank-layout-master'},
            component: require('@/views/game/play.vue').default,
        },
        {
            path: '/:lang?/deposit_vendor',
            name: ROUTE_NAME.VENDOR_POPUP,
            meta: {layout: 'blank-layout-master'},
            component: require('@/views/bank/vendorPopUp.vue').default,
        },
        {
            path: '/:lang?/bank',
            meta: {layout: 'layout-master'},
            component: require('@/views/bank/bankLayout.vue').default,
            beforeEnter: (to, from, next) => {
                loginGuard(to, from, next)
            },
            children: [
                {
                    path: 'funds',
                    name: ROUTE_NAME.FUNDS,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/bank/funds.vue').default,
                },
                // {
                //     path: 'deposit',
                //     name: ROUTE_NAME.DEPOSIT,
                //     meta: {layout: 'layout-master'},
                //     component: require('@/views/bank/deposit.vue').default,
                // },
                {
                    path: 'deposit-success',
                    name: ROUTE_NAME.DEPOSIT_SUCCESS,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/bank/depositSuccess.vue').default,
                },
                // {
                //     path: 'withdrawal',
                //     name: ROUTE_NAME.WITHDRAWAL,
                //     meta: {layout: 'layout-master'},
                //     component: require('@/views/bank/withdrawal.vue').default,
                // },
                {
                    path: 'transaction',
                    name: ROUTE_NAME.TRANSACTION,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/bank/transaction.vue').default,
                },
                {
                    path: 'voucher',
                    name: ROUTE_NAME.CLAIM_VOUCHER,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/bank/claimVoucher.vue').default,
                },
                {
                    path: 'transfer',
                    name: ROUTE_NAME.TRANSFER,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/bank/transfer.vue').default,
                },
                {
                    path: 'vip',
                    name: ROUTE_NAME.VIP_PROFILE,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/profile/vipPage.vue').default,
                },
                {
                    path: 'transfer-history',
                    name: ROUTE_NAME.TRANSFER_HISTORY,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/profile/transactionHistory.vue').default,
                },
                {
                    path: 'betting-history',
                    name: ROUTE_NAME.BETTING_HISTORY,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/history/historyOverview.vue').default,
                },
                {
                    path: 'turnover-history',
                    name: ROUTE_NAME.TURNOVER_RECORD,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/turnover/historyOverview.vue').default,
                },
                {
                    path: 'transaction-history',
                    name: ROUTE_NAME.TRANSACTION_HISTORY,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/profile/transactionHistory.vue').default,
                },
                {
                    path: 'bonus-history',
                    name: ROUTE_NAME.BONUS_HISTORY,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/profile/transactionHistory.vue').default,
                },
                {
                    path: 'redemption-history',
                    name: ROUTE_NAME.REDEMPTION_HISTORY,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/profile/transactionHistory.vue').default,
                }
            ]
        },
        {
            path: '/:lang?/payment/process',
            name: ROUTE_NAME.PROCESS_TRANSACTION,
            meta: {layout: 'blank-layout-master'},
            component: require('@/views/bank/process.vue').default,
        },
        {
            path: '/:lang?/payment/pay-secure',
            name: ROUTE_NAME.PAY_SECURE,
            meta: {layout: 'blank-layout-master'},
            component: require('@/views/bank/paysecure.vue').default,
        },
        {
            path: '/:lang?/profile/menu',
            name: ROUTE_NAME.PROFILE_MENU,
            meta: {layout: 'layout-master'},
            component: require('@/views/profile/menu.vue').default,
            beforeEnter: (to, from, next) => {
                loginGuard(to, from, next)
            }
        },
        {
            path: '/:lang?/profile/logout',
            name: ROUTE_NAME.PROFILE_LOGOUT,
            meta: {layout: 'layout-master'},
            component: require('@/views/profile/logout.vue').default,
        },
        {
            path: '/:lang?/profile',
            meta: {layout: 'layout-master'},
            component: () => import('@/views/profile/profileLayout.vue'),
            children: [
                {
                    path: 'agent/apply',
                    name: ROUTE_NAME.APPLY_AGENT,
                    meta: {layout: 'layout-master'},
                    component: () => import('@/views/profile/applyAgent.vue')
                },
                { path: '/agent/apply', redirect: '/profile/agent/apply', pathToRegexpOptions: { strict: true } }
            ]
        },
        {
            path: '/:lang?/profile',
            meta: {layout: 'layout-master'},
            component: require('@/views/profile/profileLayout.vue').default,
            beforeEnter: (to, from, next) => {
                loginGuard(to, from, next)
            },
            children: [
                {
                    path: 'account',
                    name: ROUTE_NAME.PROFILE,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/profile/account.vue').default,
                },
                {
                    path: 'personal',
                    name: ROUTE_NAME.PERSONAL,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/profile/personal.vue').default,
                },
                {
                    path: 'message',
                    name: ROUTE_NAME.ANNOUNCEMENT,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/profile/announcement.vue').default,
                },
                {
                    path: 'change-password',
                    name: ROUTE_NAME.CHANGE_PASSWORD,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/profile/changePassword.vue').default,
                },
                {
                    path: 'bank-account',
                    name: ROUTE_NAME.BANK_ACCOUNT,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/profile/bankAccount.vue').default,
                },
                {
                    path: 'inbox',
                    name: ROUTE_NAME.INBOX,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/profile/inbox.vue').default,
                },
                {
                    path: 'rewards',
                    name: ROUTE_NAME.REWARDS,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/profile/rewards.vue').default,
                },
            ]
        },
        {
            path: '/:lang?/rewardStore',
            meta: {layout: 'layout-master'},
            component: require('@/views/rewardsStore.vue').default,
            children: [

                {
                    path: '/:lang?/rewardStore',
                    name: ROUTE_NAME.REWARDSTORE,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/rewardsStore.vue').default,
                },
            ]
        },
        {
            path: '/:lang?/daily-checkin',
            name: ROUTE_NAME.DAILY_CHECKIN,
            meta: {layout: 'layout-master'},
            component: require('@/views/profile/rewards.vue').default,
        },
        {
            path: '/:lang?/voucher',
            name: ROUTE_NAME.CLAIM_VOUCHER,
            meta: {layout: 'layout-master'},
            component: require('@/views/bank/claimVoucher.vue').default,
        },
        // {
        //     path: '/rewards',
        //     meta: {layout: 'layout-master'},
        //     component: require('@/views/rewards/rewardLayout.vue').default,
        //     children: [
        //         {
        //             path: ':type',
        //             name: ROUTE_NAME.REWARDS_BY_TYPE,
        //             meta: {layout: 'layout-master'},
        //             component: require('@/views/rewards/rewards.vue').default,
        //         }
        //     ]
        // },
        // {path: '/promotion', redirect: '/promotion/', pathToRegexpOptions: {strict: true}},
        {
            path: '/:lang?/info',
            meta: {layout: 'layout-master'},
            component: require('@/views/info/infoLayout.vue').default,
            children: [
                {
                    path: 'faq',
                    name: ROUTE_NAME.FAQ,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/info/faq.vue').default,
                },
                {
                    path: 'about-us',
                    name: ROUTE_NAME.ABOUT_US,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/info/aboutUs.vue').default,
                },
                {
                    path: 'contact-us',
                    name: ROUTE_NAME.CONTACT_US,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/info/contactUs.vue').default,
                },
                {
                    path: 'tnc',
                    name: ROUTE_NAME.TNC,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/info/tnc.vue').default,
                }
            ]
        },
        {
            path: '/:lang?/promotion',
            meta: {
                layout: 'layout-master',
            },
            component: require('@/views/promotion/promotionLayout.vue').default,
            children: [
                {
                    path: '/:lang?/promotion',
                    name: ROUTE_NAME.PROMOTION,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/promotion/promotion.vue').default,
                },
                {
                    path: 'in-progress',
                    name: ROUTE_NAME.PROMOTION_IN_PROGRESS,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/promotion/inprogress.vue').default,
                    beforeEnter: (to, from, next) => {
                        loginGuard(to, from, next)
                    }
                },
                {
                    path: 'history',
                    name: ROUTE_NAME.PROMOTION_HISTORY,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/promotion/history.vue').default,
                    beforeEnter: (to, from, next) => {
                        loginGuard(to, from, next)
                    }
                }
            ]
        },
        {
            path: '/:lang?/affiliate/home',
            name: ROUTE_NAME.AFFILIATE_HOME,
            meta: {layout: 'layout-master'},
            component: require('@/views/affiliate/home.vue').default,
        },
        {
            path: '/:lang?/affiliate',
            meta: {layout: 'layout-master'},
            component: require('@/views/affiliate/affiliateLayout.vue').default,
            beforeEnter: (to, from, next) => {
                loginGuard(to, from, next)
            },
            children: [
                {
                    path: 'members',
                    name: ROUTE_NAME.AFFILIATE_MEMBER_REPORT,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/affiliate/memberReport.vue').default,
                },
                {
                    path: 'members-detail',
                    name: ROUTE_NAME.AFFILIATE_MEMBER_DETAIL_REPORT,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/affiliate/memberDetailReport.vue').default,
                },
                {
                    path: 'games-report',
                    name: ROUTE_NAME.AFFILIATE_GAME_REPORT,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/affiliate/gameReport.vue').default,
                },
                {
                    path: 'agent-report',
                    name: ROUTE_NAME.AFFILIATE_AGENT_REPORT,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/affiliate/agentReport.vue').default,
                },
                {
                    path: 'link',
                    name: ROUTE_NAME.AFFILIATE_LINK,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/affiliate/link.vue').default,
                }
            ]
        },
        {
            path: '/:lang?/affiliate/bank',
            meta: {layout: 'layout-master'},
            component: require('@/views/affiliatePayment/bankLayout.vue').default,
            beforeEnter: (to, from, next) => {
                loginGuard(to, from, next)
            },
            children: [
                {
                    path: 'withdrawal',
                    name: ROUTE_NAME.AFFILIATE_WITHDRAWAL,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/affiliatePayment/withdrawal.vue').default,
                },
                {
                    path: 'transaction',
                    name: ROUTE_NAME.AFFILIATE_TRANSACTION,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/affiliatePayment/transaction.vue').default,
                },
                {
                    path: 'account',
                    name: ROUTE_NAME.AFFILIATE_BANK_ACCOUNT,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/affiliatePayment/account.vue').default,
                }
            ]
        },
        {
            path: '/:lang?/luckywheel',
            meta: {layout: 'layout-master'},
            component: require('@/views/rewards/WheelOfFortune.vue').default,
            name: ROUTE_NAME.WOF
        },
        {
            path: '/:lang?/mluckywheel',
            meta: {layout: 'layout-master'},
            component: require('@/views/rewards/MobileWheelOfFortune.vue').default,
            name: ROUTE_NAME.MWOF
        },
        {
            path: '/:lang?/grab-coin',
            meta: {layout: 'layout-master'},
            component: require('@/views/rewards/GrabCoin.vue').default,
            name: ROUTE_NAME.GRAB_COIN
        },
        {
            path: '/:lang?/card-mania',
            meta: {layout: 'layout-master'},
            component: require('@/views/rewards/CardMania.vue').default,
            name: ROUTE_NAME.CARD_MANIA
        },
        {
            path: '/:lang?/referral',
            meta: {layout: 'layout-master'},
            component: require('@/views/referral/referralLayout.vue').default,
            children: [
                {
                    path: 'tier',
                    name: ROUTE_NAME.REFERRAL_TIER,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/referral/referralTier.vue').default,
                },
                {
                    path: 'summary',
                    name: ROUTE_NAME.REFERRAL_SUMMARY,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/referral/referralSummary.vue').default,
                },
                {
                    path: 'report',
                    name: ROUTE_NAME.REFERRAL_REPORT,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/referral/referralReport.vue').default,
                },
                {
                    path: 'downlinereport',
                    name: ROUTE_NAME.REFERRAL_DOWNLINE_REPORT,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/referral/downlineReport.vue').default,
                },
                {
                    path: 'status',
                    name: ROUTE_NAME.REFERRAL_STATUS,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/referral/status.vue').default,
                },
                {
                    path: 'history',
                    name: ROUTE_NAME.REFERRAL_HISTORY,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/referral/referralHistory.vue').default,
                },
            ]
        },
        {
            path: '/:lang?/viptiers',
            meta: {layout: 'layout-master'},
            component: require('@/views/viptiers/viptier.vue').default,
            children: [

                {
                    path: 'vip-tier',
                    name: ROUTE_NAME.VIPTIER,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/viptiers/viptier.vue').default,
                },
            ]
        },
        {
            path: '/:lang?/referral',
            meta: {layout: 'layout-master'},
            component: require('@/views/bank/bankLayout.vue').default,
            children: [
                {
                    path: 'bonus',
                    name: ROUTE_NAME.REFERRAL_BONUS,
                    meta: {layout: 'layout-master'},
                    component: require('@/views/profile/affiliateBonus.vue').default,
                }
            ]
        },
        {
            path: '/:lang?/krikbet',
            meta: {layout: 'layout-master'},
            component: require('@/views/vip/vipLayout.vue').default,
            children: [
                {
                    path: 'challenge',
                    name: ROUTE_NAME.VIP,
                    meta: {
                        layout: 'layout-master',
                    },
                    component: require('@/views/vip/vip.vue').default,
                    pathToRegexpOptions: {strict: true}
                },
                {path: 'challenge/', redirect: 'challenge', pathToRegexpOptions: {strict: true}},
            ]
        },
        {
            path: '/:lang?/error',
            component: require('@/views/error/errorLayout.vue').default,
            children: [
                {
                    path: 'ip-restriction',
                    name: ROUTE_NAME.ERROR_403,
                    meta: {layout: 'blank-layout-master'},
                    component: require('@/views/error/403.vue').default,
                },
                {
                    path: 'page-not-found',
                    name: ROUTE_NAME.ERROR_404,
                    meta: {layout: 'blank-layout-master'},
                    component: require('@/views/error/404.vue').default,
                }
            ]
        },
        {
            path: '/:lang?/rewards/instantRebate',
            name: ROUTE_NAME.REBATE,
            meta: { layout: 'layout-master' },
            component: require('@/views/rewards/instantRebate.vue').default,
        },
        {
            path: '/:lang?/rewards/rebateHistory',
            name: ROUTE_NAME.REBATE_HISTORY,
            meta: { layout: 'layout-master' },
            component: require('@/views/rewards/rebateHistory.vue').default,
        },
        {
            path: '/:lang?/rewards/rebateHistoryDetail',
            name: ROUTE_NAME.REBATE_HISTORY_DETAIL,
            meta: { layout: 'layout-master' },
            component: require('@/views/rewards/rebateHistoryDetail.vue').default,
        },
        { path: '/home', redirect: '/', pathToRegexpOptions: { strict: true } },
        {
            path: ['/:lang?'],
            alias: '/:lang/home',
            name: ROUTE_NAME.HOME,
            meta: {
                layout: 'layout-master'
            },
            component: require('@/views/home.vue').default,
            pathToRegexpOptions: { strict: true },
        },
        {
            path: '/',
            name: ROUTE_NAME.HOME,
            meta: { layout: 'layout-master' },
            component: require('@/views/home.vue').default,
            // beforeEnter: (to, from, next) => {
            //   // testGuard(to,from,next)
            // }
        },
        {
            path: '*',
            redirect: {name: ROUTE_NAME.ERROR_404}
        }
    ],
    /* TO AUTO BACK TO TOP */
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

export default router
