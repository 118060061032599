import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import { SHARED } from '@/constants/constants'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@/filters'
import '@/plugins/vuetify'
import './styles.scss'
import LayoutMaster from '@/components/layout/LayoutMaster.vue'
import BlankLayoutMaster from '@/components/layout/BlankLayoutMaster.vue'
import AppFormField from '@/components/FormField.vue'
import AgentAppFormField from '@/components/AgentFormField'
import AppButton from '@/components/Button.vue'
import ButtonPrimary from '@/components/ButtonPrimary.vue'
import AppDialog from '@/components/dialog.vue'
import AppDateTimePicker from '@/components/DateTimePicker.vue'
import ProfileSheet from '@/components/profileSheet.vue'
import VueClipboard from 'vue-clipboard2'
import VueAnalytics from 'vue-analytics'
import Siema from 'vue2-siema'
import OneSignalVue from 'onesignal-vue'
import Flicking from '@egjs/vue-flicking'
import '@egjs/vue-flicking/dist/flicking.css'
import VueCaptchaCode from '@johnnyguan/vue-captcha-code'

// Or, if you have to support IE9
import '@egjs/vue-flicking/dist/flicking-inline.css'

Vue.use(Flicking)
Vue.prototype.$eventHub = new Vue()
Vue.use(Siema)
Vue.use(VueClipboard)
Vue.use(VueAnalytics, {
  id: SHARED.GA_TRACKING_CODE,
  router
})
Vue.use(OneSignalVue)
Vue.use(VueCaptchaCode)
Vue.component('layout-master', LayoutMaster)
Vue.component('blank-layout-master', BlankLayoutMaster)
Vue.component('app-form-field', AppFormField)
Vue.component('agent-app-form-field', AgentAppFormField)
Vue.component('app-button', AppButton)
Vue.component('button-primary', ButtonPrimary)
Vue.component('app-dialog', AppDialog)
Vue.component('app-date-time-picker', AppDateTimePicker)
Vue.component('profile-sheet', ProfileSheet)
Vue.component('v-style', {
  render: function(createElement) {
    return createElement('style', this.$slots.default)
  }
})

Vue.config.productionTip = false
export const eventBus = new Vue()
Vue.prototype.$eventHub = new Vue()
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  beforeMount() {
    let currentDomain = window.location.hostname
    console.log('One signal currentdomain : ' + currentDomain)
    if (/\bkrikya.com\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: '9c76e1d2-a7e2-45e5-843d-31ed9766af6e' }).then(() => {
        console.log('app id key set successful', '9c76e1d2-a7e2-45e5-843d-31ed9766af6e')
      })
    }
    if (/\bkrikya.co\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: '040a12e6-118f-49ef-a206-38173a0c5f07' }).then(() => {
        console.log('app id key set successful', '040a12e6-118f-49ef-a206-38173a0c5f07')
      })
    }
    if (/\bkrikya.group\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: '4543e1a5-4195-4f1f-bc2c-78a52498a9ff' }).then(() => {
        console.log('app id key set successful', '4543e1a5-4195-4f1f-bc2c-78a52498a9ff')
      })
    }
    if (/\bkrikya.best\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: 'fb79d44e-a043-4f0d-b786-dc04304207e2' }).then(() => {
        console.log('app id key set successful', 'fb79d44e-a043-4f0d-b786-dc04304207e2')
      })
    }
    if (/\bkrikya11.com\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: '54d6f806-3eeb-41c8-a80f-0b6d2f49ad90' }).then(() => {
        console.log('app id key set successful', '54d6f806-3eeb-41c8-a80f-0b6d2f49ad90')
      })
    }
    if (/\bkrikya.pro\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: 'a6f36e33-119c-4738-8c64-49e3ffb9b264' }).then(() => {
        console.log('app id key set successful', 'a6f36e33-119c-4738-8c64-49e3ffb9b264')
      })
    }
    if (/\bkrikya.club\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: '72ade366-ac74-444c-9496-544c3a8aafff' }).then(() => {
        console.log('app id key set successful', '72ade366-ac74-444c-9496-544c3a8aafff')
      })
    }
    if (/\bkrikya.app\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: 'b62d7397-a3fe-4019-af8c-7a651858be2d' }).then(() => {
        console.log('app id key set successful', 'b62d7397-a3fe-4019-af8c-7a651858be2d')
      })
    }
    if (/\bkrikya22.com\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: '3ba2378d-a5a0-4f9a-a101-e12557f8a2fa' }).then(() => {
        console.log('app id key set successful', '3ba2378d-a5a0-4f9a-a101-e12557f8a2fa')
      })
    }
    if (/\bkrikya44.com\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: 'f40c825d-8dbf-4afd-91d5-0e6e6d3c973a' }).then(() => {
        console.log('app id key set successful', 'f40c825d-8dbf-4afd-91d5-0e6e6d3c973a')
      })
    }
    if (/\bkrikya55.com\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: '1f58bbab-04b4-4212-852c-1a13227b5544' }).then(() => {
        console.log('app id key set successful', '1f58bbab-04b4-4212-852c-1a13227b5544')
      })
    }
    if (/\bkrikya66.com\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: 'c03201af-7715-40ec-a4ae-b46f85606388' }).then(() => {
        console.log('app id key set successful', 'c03201af-7715-40ec-a4ae-b46f85606388')
      })
    }
    if (/\bkrikya77.com\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: 'f68a3d1a-8eca-4737-8a1e-87268975cd2c' }).then(() => {
        console.log('app id key set successful', 'f68a3d1a-8eca-4737-8a1e-87268975cd2c')
      })
    }
    if (/\bkrikya.one\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: 'b21c9527-e58d-4698-82ce-19697cd18bc8' }).then(() => {
        console.log('app id key set successful', 'b21c9527-e58d-4698-82ce-19697cd18bc8')
      })
    }
    if (/\bbdkrikya.online\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: '4fddbd27-13c9-48f5-a244-6e86e318f985' }).then(() => {
        console.log('app id key set successful', '4fddbd27-13c9-48f5-a244-6e86e318f985')
      })
    }
    if (/\bbdkrikya.net\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: 'e41e8f3c-9515-42ce-a52e-cd5ae435ada4' }).then(() => {
        console.log('app id key set successful', 'e41e8f3c-9515-42ce-a52e-cd5ae435ada4')
      })
    }
    if (/\bkrikya.io\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: 'd2cfdaba-66d9-4a20-b8c7-6ae7d8e23dde' }).then(() => {
        console.log('app id key set successful', 'd2cfdaba-66d9-4a20-b8c7-6ae7d8e23dde')
      })
    }
    if (/\bkrikya.today\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: '7e33c33e-cc36-418b-8d4c-2edab7c35774' }).then(() => {
        console.log('app id key set successful', '7e33c33e-cc36-418b-8d4c-2edab7c35774')
      })
    }
    if (/\bkrikya.tech\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: '1be6e355-9df9-430a-83c9-fb014009de6b' }).then(() => {
        console.log('app id key set successful', '1be6e355-9df9-430a-83c9-fb014009de6b')
      })
    }
    if (/\bkrikya.business\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: 'fd4617fd-21b6-4494-ab2f-f850fa77bfd2' }).then(() => {
        console.log('app id key set successful', 'fd4617fd-21b6-4494-ab2f-f850fa77bfd2')
      })
    }
    if (/\bkrikya.lol\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: 'a0abc7c1-af22-4ee9-8a46-6be8883bd2fa' }).then(() => {
        console.log('app id key set successful', 'a0abc7c1-af22-4ee9-8a46-6be8883bd2fa')
      })
    }
    if (/\bkrikya.space\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: '17767b8c-a379-4d63-8b5b-b6186cef5001' }).then(() => {
        console.log('app id key set successful', '17767b8c-a379-4d63-8b5b-b6186cef5001')
      })
    }
    if (/\bkrikyavip.com\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: '13ecaed4-2883-4ca7-a5c3-304a2fd2e2d4' }).then(() => {
        console.log('app id key set successful', '13ecaed4-2883-4ca7-a5c3-304a2fd2e2d4')
      })
    }
    if (/\b16896kk332.com\b/.test(currentDomain)) {
      this.$OneSignal.init({ appId: 'de122127-5513-43e6-94b5-c32856a2d5c5' }).then(() => {
        console.log('app id key set successful', 'de122127-5513-43e6-94b5-c32856a2d5c5')
      })
    }
  }
}).$mount('#app')
