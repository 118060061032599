import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdi'
    },
    theme: {
        dark: false,
        themes: {
            light: {
                light_black: '#303030',
                body: '#d5d5d5',
                base: '#d5d5d5', //white (background color)
                base_2: '#D5D5D5', //lightgray
                base_3: '#f5f5f5', //whitesmoke
                base_4: '#fafafa', //snow
                primary: '#378F6A', //darkgreen
                primary_2: '#ffdf1a', //yellow
                primary_3: '#252A6F', //mediumaquamarine
                secondary: '#f56908', // yellow
                secondary_2: '#ff0000', //red
                secondary_3: '#0B5376', //gainsboro,
                buttonPrimary: '#14805e', // yellow
                buttonHover: '#3c7591', //steelblue
                buttonPressed: '#093a52', //darkslategray,
                buttonDisabled: '#d5d5d5', //lightgray
                buttonWhite: '#ffffff',
                title_color: '#14805e', // darkgreen
                text_color: '#000000',
                description: '#C6C6C6', // grey
                hightlight_divider: '#14805e', //darkgreen
                icon_color: '#14805e', // darkgreen
                navigation_drawer: '#c5c5c5', // black
                footer_color: '#d5d5d5',
                disabled: '#C6C6C6',
                background: '#d5d5d5',
                background_2: "#f56908",
                divider: '#3A3A3A',
                text_reverse: '#ffffff',
                border: '#d3d3d3',
                fullWhite: '#ffffff'
            },
            dark: {
                body: '#111111',
                base: '#111111', //black (background color)
                base_2: '#F2F2F2', //whitesmoke
                base_3: '#D5D5D5', //lightgray
                base_4: '#333333', //darkslategray
                title_color: '#14805e', // darkgreen
                text_color: '#ffffff',
                description: '#999999', // grey
                primary: '#14805e', //darkgreen
                primary_2: '#ffdf1a', //yellow
                primary_3: '#252A6F', //mediumaquamarine
                secondary: '#f56908', // yellow
                secondary_2: '#ff0000', //red
                secondary_3: '#0B5376', //gainsboro,
                buttonPrimary: '#14805e', // darkgreen
                buttonHover: '#3c7591', //steelblue
                buttonPressed: '#093a52', //darkslategray,
                buttonDisabled: '#d5d5d5', //lightgray
                buttonGold_primary: '#FFA15C',
                buttonGold_secondary: '#E2D200',
                navigator_active: '#FFCE5E',
                hightlight_divider: '#14805e', //darkgreen
                icon_color: '#14805e', // yellow
                navigation_drawer: '#252525', // black
                footer_color: '#111111',
                disabled: '#C6C6C6',
                background: '#111111',
                background_2: "#f56908",
                divider: '#3A3A3A',
                text_reverse: '#000000',
                border: '#d3d3d3'
            }
        },
        options: {
            customProperties: true
        }
    },
    breakpoint: {
        thresholds: {
            xs: 600,
            sm: 960,
            md: 1024,
            lg: 1350,
            xl: 1650
        },
        scrollBarWidth: 15
    }
})
